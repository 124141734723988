<template>
  <b-modal
    :id="idModal"
    ref="edit-basis-information-modal-form-default"
    :title="$t('views.caseTemplateEdition.fields.update-field')"
    centered
    header-bg-variant="primary"
    header-text-variant="light"
    :ok-title="$t('general.ok')"
    :cancel-title="$t('general.cancel')"
    :busy="loading"
    v-model="modalShow"
    @ok="handleOk"
    @hidden="resetModal"
    @show="onShow"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :label="$t('views.caseTemplateEdition.fields.name')"
        label-for="name-input"
        :invalid-feedback="$t('views.caseTemplateEdition.fields.name-invalid')"
        label-cols="12"
        content-cols="12"
        :state="state.name"
      >
        <b-form-input
          id="name-input"
          :disabled="loading"
          :placeholder="$t('views.caseTemplateEdition.fields.name-placeholder')"
          v-model="form.name"
          :state="state.name"
          maxlength="30"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('views.caseTemplateEdition.fields.value')"
        label-for="value-input"
        label-cols="12"
        content-cols="12"
      >
        <b-form-input
          id="description-input"
          :disabled="loading"
          :placeholder="
            $t('views.caseTemplateEdition.fields.value-placeholder')
          "
          v-model="form.value"
          maxlength="150"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('views.caseTemplateEdition.fields.mandatory')"
        label-for="checkbox-input"
        label-cols="9"
      >
        <b-form-checkbox
          v-model="form.mandatory"
          id="checkbox-input"
          :disabled="loading"
          button
          @change="mandatoryChange"
          :button-variant="form.mandatory ? 'success' : 'danger'"
        >
          {{
            form.mandatory
              ? $t("views.caseTemplateEdition.fields.mandatory-true")
              : $t("views.caseTemplateEdition.fields.mandatory-false")
          }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="form.mandatory"
        :label="$t('views.caseTemplateEdition.fields.reviewer')"
        label-for="checkbox-input"
        label-cols="9"
      >
        <b-form-checkbox
          v-model="form.reviewer"
          id="checkbox-input"
          :disabled="loading"
          button
          :button-variant="form.reviewer ? 'success' : 'danger'"
        >
          <b-icon
            :icon="form.reviewer ? 'check-circle-fill' : 'x-circle-fill'"
          />
        </b-form-checkbox>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    show: Boolean,
    selectedRow: Object,
  },
  data() {
    return {
      form: {
        name: null,
        value: null,
        mandatory: false,
        reviewer: false,
      },
      state: {
        name: null,
      },
      idModal: "edit-basis-information-modal-form-default",
      loading: false,
    };
  },
  computed: {
    modalShow() {
      return this.show;
    },
  },
  methods: {
    ...mapActions("CaseTemplates", ["putCaseTemplateField"]),
    checkFormValidity() {
      let valid = (this.state.name = true);
      if (!this.form.name) this.state.name = valid = false;
      return valid;
    },
    mandatoryChange() {
      this.form.reviewer = false;
    },
    resetModal() {
      this.form = {
        name: null,
        value: null,
        mandatory: false,
        reviewer: false,
      };
      this.state = {
        name: null,
      };
      this.loading = false;
      this.$emit("modalClose");
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.loading = true;
      this.putCaseTemplateField({
        idCaseTemplateField: this.selectedRow.idCaseTemplateField,
        fieldTitle: this.form.name,
        fieldValue: this.form.value,
        mandatory: this.form.mandatory,
        reviewerRequired: this.form.reviewer,
      })
        .then((response) => {
          this.$bvToast.toast(response.data.information.text, {
            variant: "success",
            title: this.$t("views.caseTemplateEdition.fields.edit-field"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide(this.idModal);
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(
            errorMsg != undefined
              ? errorMsg
              : this.$t("views.caseTemplateEdition.fields.generic-forbidden"),
            {
              variant: "danger",
              title: this.$t("views.caseTemplateEdition.fields.edit-field"),
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.loading = false;
          this.state = {
            name: null,
          };
        });
    },
    onShow() {
      this.form.name = this.selectedRow.fieldTitle;
      this.form.value = this.selectedRow.fieldValue;
      this.form.mandatory = this.selectedRow.mandatory;
      this.form.reviewer = this.selectedRow.reviewerRequired;
    },
  },
};
</script>
