<template>
  <b-modal
    :id="idModal"
    ref="edit-basis-information-modal-form-name"
    :title="$t('views.caseTemplateEdition.fields.update-name')"
    centered
    header-bg-variant="primary"
    header-text-variant="light"
    :ok-title="$t('general.ok')"
    :cancel-title="$t('general.cancel')"
    :busy="loading"
    v-model="modalShow"
    @ok="handleOk"
    @hidden="resetModal"
    @show="onShow"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :label="$t('views.caseTemplateEdition.fields.value')"
        label-for="value-input"
        label-cols="12"
        content-cols="12"
        :invalid-feedback="$t('views.cases.notAllowedSymbols')"
      >
        <b-form-input
          id="description-input"
          :disabled="loading"
          :placeholder="
            $t('views.caseTemplateEdition.fields.value-placeholder')
          "
          v-model="form.value"
          maxlength="150"
          required
          :formatter="formatter"
          :state="state.name"
        ></b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    show: Boolean,
    idCaseTemplate: Number,
    selectedRow: Object,
  },
  data() {
    return {
      form: {
        value: null,
      },
      state: {
        name: null,
      },
      idModal: "edit-basis-information-modal-form-name",
      loading: false,
      caseNameRegex: /\/|\*|\?|\[|\]|:|\|/g,
    };
  },
  computed: {
    modalShow() {
      return this.show;
    },
  },
  methods: {
    ...mapActions("CaseTemplates", ["putCaseTemplateFieldName"]),
    formatter(value) {
      if (value.match(this.caseNameRegex)) {
        this.state.name = false;
      } else {
        this.state.name = null;
      }
      return value.replace(this.caseNameRegex, "");
    },
    resetModal() {
      this.form = {
        name: null,
        value: null,
        mandatory: false,
      };
      this.state = {
        name: null,
      };
      this.loading = false;
      this.$emit("modalClose");
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.state.name = null;
      this.loading = true;
      this.putCaseTemplateFieldName({
        idCaseTemplate: this.idCaseTemplate,
        defaultName: this.form.value,
      })
        .then((response) => {
          this.$bvToast.toast(response.data.information.text, {
            variant: "success",
            title: this.$t("views.caseTemplateEdition.fields.update-name"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide(this.idModal);
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(
            errorMsg != undefined
              ? errorMsg
              : this.$t("views.caseTemplateEdition.fields.generic-forbidden"),
            {
              variant: "danger",
              title: this.$t("views.caseTemplateEdition.fields.update-name"),
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.loading = false;
          this.state = {
            name: null,
          };
        });
    },
    onShow() {
      this.form.value = this.selectedRow.fieldValue;
    },
  },
};
</script>
