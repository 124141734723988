<template>
  <div>
    <b-tab :title="$t('views.caseTemplateEdition.basisInformation')" active>
      <b-container>
        <b-row class="mb-3">
          <b-col>
            <h2>{{ $t("views.caseTemplateEdition.fields.title") }}</h2>
          </b-col>
          <b-col align-self="stretch">
            <b-button-toolbar key-nav>
              <b-button-group class="mx-1 ml-auto">
                <AddRow :busy="busy" :idCaseTemplate="idCaseTemplate" />
                <EditRow
                  :selectedRow="selectedRow"
                  :idCaseTemplate="idCaseTemplate"
                />
                <DeleteCaseTemplateField
                  :disabled="!selectedRow || defaultRowSelected(selectedRow)"
                  :rowSelected="selectedRow"
                  :idCaseTemplate="idCaseTemplate"
                  @onDelete="clearSelected"
                />
              </b-button-group>
              <b-button-group class="ml-1">
                <MoveUp
                  :disabled="!selectedRow || canMoveUp(selectedRow)"
                  :selectedRow="selectedRow"
                  :items="caseTemplateFields.fieldsList"
                  :idCaseTemplate="idCaseTemplate"
                  @onMove="refreshList"
                />
                <MoveDown
                  :disabled="!selectedRow || canMoveDown(selectedRow)"
                  :selectedRow="selectedRow"
                  :items="caseTemplateFields.fieldsList"
                  :idCaseTemplate="idCaseTemplate"
                  @onMove="refreshList"
                />
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </b-container>
      <b-table
        striped
        hover
        responsive
        stacked="md"
        fixed
        thead-class="bg-primary text-light"
        :fields="fields"
        :items="itemsBasisInformation"
        sort-by="sequence"
        :busy="busy || loading"
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
      >
        <template v-slot:cell(mandatory)="row">
          <b-icon
            :icon="row.value ? 'lock-fill' : 'unlock-fill'"
            font-scale="2"
          />
        </template>
        <template v-slot:cell(reviewerRequired)="row">
          <b-icon
            :icon="row.value ? 'check-circle-fill' : 'x-circle-fill'"
            font-scale="2"
            :variant="row.value ? 'success' : 'danger'"
          />
        </template>
      </b-table>
    </b-tab>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddRow from "@/components/CaseTemplateEdition/NewBasisInformation.vue";
import EditRow from "@/components/CaseTemplateEdition/EditCaseTemplateField.vue";
import DeleteCaseTemplateField from "@/components/CaseTemplateEdition/DeleteCaseTemplateField.vue";
import MoveUp from "@/components/CaseTemplateEdition/MoveUpBasisInformation.vue";
import MoveDown from "@/components/CaseTemplateEdition/MoveDownBasisInformation.vue";

export default {
  components: {
    AddRow,
    EditRow,
    DeleteCaseTemplateField,
    MoveUp,
    MoveDown,
  },
  props: {
    busy: Boolean,
    idCaseTemplate: {
      type: Number,
      required: true,
    },
    idCaseTemplateField: Number,
  },
  data() {
    return {
      show: false,
      selectedRow: null,
      loading: false,
      movedItemId: null,
      idFieldToChangePositionUp: null,
      idFieldToChangePositionDown: null,
    };
  },
  watch: {
    idCaseTemplate() {
      this.loading = true;
      this.getCaseTemplateFields({
        idCaseTemplate: this.idCaseTemplate,
      })
        .then(() => {})
        .catch((e) => {
          console.log(e.response.data.text);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters("CaseTemplates", ["caseTemplateFields"]),
    fields() {
      const titles = [
        {
          key: "fieldTitle",
          label: this.$t("views.caseTemplateEdition.fields.field-title-name"),
        },
        {
          key: "fieldValue",
          label: this.$t("views.caseTemplateEdition.fields.field-title-value"),
        },
        {
          key: "mandatory",
          label: this.$t(
            "views.caseTemplateEdition.fields.field-title-mandatory"
          ),
        },
        {
          key: "reviewerRequired",
          label: this.$t(
            "views.caseTemplateEdition.fields.field-title-reviewer"
          ),
        },
      ];
      return titles;
    },
    itemsBasisInformation() {
      const defaultRows = [
        {
          idCaseTemplateField: -1,
          fieldTitle: "Name",
          fieldValue: this.caseTemplateFields.caseDefaultName,
          mandatory: true,
          reviewerRequired: false,
          sequence: -2,
        },
        {
          idCaseTemplateField: -2,
          fieldTitle: "Study Type",
          fieldValue: this.caseTemplateFields.studyTypeName,
          studyType: this.caseTemplateFields.studyType,
          mandatory: true,
          reviewerRequired: false,
          sequence: -1,
        },
        {
          idCaseTemplateField: -3,
          fieldTitle: "Duration",
          fieldValue: this.caseTemplateFields.duration,
          mandatory: true,
          reviewerRequired: false,
          sequence: 0,
        },
      ];
      return [
        ...defaultRows,
        ...(Array.isArray(this.caseTemplateFields.fieldsList)
          ? this.caseTemplateFields.fieldsList
          : []),
      ];
    },
  },
  methods: {
    ...mapActions("CaseTemplates", ["getCaseTemplateFields"]),
    clearSelected() {
      this.selectedRow = null;
    },
    refreshList() {
      this.loading = true;
      this.getCaseTemplateFields({
        idCaseTemplate: this.idCaseTemplate,
      })
        .then(() => {})
        .catch((e) => {
          console.log(e.response.data.text);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowSelected(row) {
      if (!row[0]) {
        this.selectedRow = null;
      } else {
        this.selectedRow = row[0];
      }
    },
    defaultRowSelected(row) {
      return row.idCaseTemplateField < 0;
    },
    canMoveUp(row) {
      let defaultRow = this.defaultRowSelected(row);
      if (!defaultRow) {
        const index = this.caseTemplateFields.fieldsList.find(
          (item) => item.sequence === row.sequence
        );
        if (!index) return false;
        const unvalid = index.sequence == 1;
        return unvalid;
      } else {
        return defaultRow;
      }
    },
    canMoveDown(row) {
      let defaultRow = this.defaultRowSelected(row);
      if (!defaultRow) {
        const index = this.caseTemplateFields.fieldsList.find(
          (item) => item.sequence === row.sequence
        );
        if (!index) return false;
        const unvalid =
          index.sequence == this.caseTemplateFields.fieldsList.length;
        return unvalid;
      } else {
        return defaultRow;
      }
    },
  },
};
</script>
