<template>
  <div>
    <Alert />
    <b-card class="h-100" no-body>
      <b-overlay :show="show" spinner-variant="primary" no-wrap> </b-overlay>
      <b-skeleton-wrapper :loading="busy">
        <template #loading>
          <b-card-header header-border-variant="light">
            <b-skeleton></b-skeleton>
            <b-row>
              <b-col>
                <b-skeleton></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton></b-skeleton>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-skeleton></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton></b-skeleton>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-skeleton></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton></b-skeleton>
              </b-col>
            </b-row>
          </b-card-header>
        </template>
        <b-card-header header-border-variant="light">
          <b-form class="mb-1">
            <b-form-row>
              <b-col cols="12">
                <b-container flow>
                  <b-row>
                    <b-col md="6">
                      <h2>{{ caseTemplate.caseTemplateName }}</h2>
                    </b-col>
                    <b-col>
                      <b-row>
                        <b-col cols="5"
                          ><strong>{{
                            $t("HeaderCase.OrganizationalUnit")
                          }}</strong></b-col
                        >
                        <b-col cols="7">
                          <div class="float-left">
                            {{ caseTemplate.organizationalUnitName }}
                          </div>
                          <div class="float-right">
                            <b-button-toolbar key-nav>
                              <b-button-group class="mx-1 ml-auto">
                                <back-button />
                              </b-button-group>
                              <b-button-group class="ml-1">
                                <RenameCostCalculationTemplates />
                              </b-button-group>
                            </b-button-toolbar>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-form-row>
          </b-form>
        </b-card-header>
      </b-skeleton-wrapper>
      <div class="h-100 overflow-auto">
        <b-tabs card justified>
          <BasisInformation
            :busy="busy"
            :idCaseTemplate="caseTemplate.idCaseTemplate"
          />
          <b-tab
            :title="$t('views.caseTemplateEdition.structure')"
            @click="tab = 'Structure'"
          >
            <b-row class="mb-3">
              <b-col align-self="stretch">
                <b-button-toolbar key-nav>
                  <b-button-group class="mx-1 ml-auto">
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover
                      size="sm"
                      :title="$t('views.caseTemplateEdition.addRow')"
                      v-b-modal="'new-row-modal'"
                      id="CaseTemplateEditionAddRow"
                    >
                      <b-icon-plus />
                    </b-button>
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover
                      size="sm"
                      :title="$t('views.caseTemplateEdition.editRow')"
                      :disabled="!selectedRow"
                      v-b-modal="'update-row-modal'"
                    >
                      <b-icon-pencil />
                    </b-button>
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover
                      size="sm"
                      :title="$t('views.caseTemplateEdition.deleteRow')"
                      :disabled="!selectedRow"
                      v-b-modal="'delete-row'"
                    >
                      <b-icon-trash />
                    </b-button>
                  </b-button-group>
                  <b-button-group class="ml-1">
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover
                      size="sm"
                      :disabled="
                        !selectedRow ||
                        caseTemplate.rows.indexOf(selectedRow) == 0
                      "
                      :title="$t('views.caseTemplateEdition.moveUp')"
                      @click="moveUp"
                    >
                      <b-icon-arrow-up />
                    </b-button>
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover
                      size="sm"
                      :title="$t('views.caseTemplateEdition.moveDown')"
                      :disabled="
                        !selectedRow ||
                        caseTemplate.rows.indexOf(selectedRow) ==
                          caseTemplate.rows.length - 1
                      "
                      @click="moveDown"
                    >
                      <b-icon-arrow-down />
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
            <b-table
              striped
              hover
              responsive
              stacked="md"
              thead-class="bg-primary text-light"
              tbody-tr-class="text-right"
              :fields="fields"
              :items="caseTemplate.rows"
              :busy="busy || busyTable"
              selectable
              select-mode="single"
              @row-selected="onRowSelected"
            >
              <template #table-busy>
                <b-skeleton-table
                  show-footer
                  :rows="5"
                  :table-props="{ bordered: true, striped: true, small: true }"
                ></b-skeleton-table>
              </template>
              <template v-slot:cell(blocked)="data">
                {{
                  data.item.blocked
                    ? $t("views.caseTemplateEdition.blockedStatus")
                    : $t("views.caseTemplateEdition.unblocked")
                }}
              </template>
            </b-table></b-tab
          >
          <b-tab
            :title="$t('views.caseTemplateEdition.parameters')"
            @click="tab = 'Parameters'"
          >
            <b-row class="mb-3">
              <b-col align-self="stretch">
                <b-button-toolbar key-nav>
                  <b-button-group class="mx-1 ml-auto">
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover
                      size="sm"
                      :title="$t('views.caseTemplateEdition.addParameter')"
                      v-b-modal="'new-parameter-modal'"
                    >
                      <b-icon-plus />
                    </b-button>
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover
                      size="sm"
                      :title="$t('views.caseTemplateEdition.editParameter')"
                      :disabled="!selectedParameter"
                      v-b-modal="'update-parameter-modal'"
                    >
                      <b-icon-pencil />
                    </b-button>
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover
                      size="sm"
                      :title="$t('views.caseTemplateEdition.deleteParameter')"
                      :disabled="!selectedParameter"
                      v-b-modal="'delete-parameter'"
                    >
                      <b-icon-trash />
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
            <b-table
              striped
              hover
              responsive
              stacked="md"
              thead-class="bg-primary text-light"
              tbody-tr-class="text-right"
              :fields="paramFields"
              :items="caseTemplate.parameters"
              :busy="busy || busyTable"
              selectable
              select-mode="single"
              @row-selected="onParameterSelected"
            >
              <template #table-busy>
                <b-skeleton-table
                  show-footer
                  :rows="5"
                  :table-props="{ bordered: true, striped: true, small: true }"
                ></b-skeleton-table>
              </template> </b-table
          ></b-tab>
          <b-tab :title="$t('views.caseTemplateEdition.summations')">
            <b-row class="mb-3">
              <b-col align-self="stretch">
                <b-button-toolbar key-nav>
                  <b-button-group class="mx-1 ml-auto">
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover
                      size="sm"
                      :title="$t('views.caseTemplateEdition.addRow')"
                      v-b-modal="'add-general-summation'"
                    >
                      <b-icon-plus />
                    </b-button>

                    <b-button
                      variant="primary"
                      v-b-tooltip.hover
                      size="sm"
                      :title="$t('views.caseTemplateEdition.deleteRow')"
                      :disabled="!selectedGeneralSummation"
                      v-b-modal="'delete-general-summation'"
                    >
                      <b-icon-trash />
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
            <b-table
              striped
              hover
              responsive
              stacked="md"
              thead-class="bg-primary text-light"
              tbody-tr-class="text-right"
              :fields="generalSummationsFields"
              :items="caseTemplateGeneralSummations"
              :busy="busy || busyTable"
              selectable
              select-mode="single"
              @row-selected="onGeneralSummationSelected"
            >
              <template v-slot:cell(showSubcases)="data">
                <b-form-checkbox
                  v-model="data.item.showSubcases"
                  name="check-button"
                  switch
                  @change="updateShowSubcases(data)"
                />
              </template>
              <template v-slot:cell(showBudget)="data">
                <b-form-checkbox
                  v-model="data.item.showBudget"
                  name="check-button"
                  switch
                  @change="updateShowSubcases(data)"
                />
              </template>
              <!-- <template #table-busy>
                <b-skeleton-table
                  show-footer
                  :rows="5"
                  :table-props="{ bordered: true, striped: true, small: true }"
                ></b-skeleton-table>
              </template>
              <template v-slot:cell(blocked)="data">
                {{
                  data.item.blocked
                    ? $t("views.caseTemplateEdition.blocked")
                    : $t("views.caseTemplateEdition.unblocked")
                }}
              </template> -->
            </b-table>
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
    <NewRowModal
      :idCaseTemplate="caseTemplate.idCaseTemplate"
      :index="
        caseTemplate.rows.length > 0
          ? caseTemplate.rows[caseTemplate.rows.length - 1].index + 1
          : 0
      "
      :idOrganizationalUnit="caseTemplate.idOrganizationalUnit"
    />
    <AddGeneralSummation
      :idCaseTemplate="caseTemplate.idCaseTemplate"
      :index="
        caseTemplate.rows.length > 0
          ? caseTemplate.rows[caseTemplate.rows.length - 1].index + 1
          : 0
      "
      :idOrganizationalUnit="caseTemplate.idOrganizationalUnit"
    />
    <UpdateRowModal
      :idCaseTemplate="caseTemplate.idCaseTemplate"
      :index="caseTemplate.rows.indexOf(selectedRow)"
      :idOrganizationalUnit="caseTemplate.idOrganizationalUnit"
      :blocked="selectedRow ? selectedRow.blocked : false"
      :rowName="selectedRow ? selectedRow.rowName : ''"
      :idMasterForm="selectedRow ? selectedRow.idMasterForm : 0"
      :idCaseTemplateRow="selectedRow ? selectedRow.idTemplateRow : 0"
    />
    <Confirm
      id="delete-general-summation"
      :title="$t('views.caseTemplateEdition.deleteGS')"
      :message="$t('views.caseTemplateEdition.deleteConfirmGS')"
      @onOk="onDeleteGeneralSummation"
    />
    <NewParamModal
      :idCaseTemplate="caseTemplate.idCaseTemplate"
      :idOrganizationalUnit="caseTemplate.idOrganizationalUnit"
    />
    <UpdateParamModal
      :idCaseTemplate="caseTemplate.idCaseTemplate"
      :defaultValue="selectedParameter ? selectedParameter.defaultValue : 0"
      :idParameter="selectedParameter ? selectedParameter.idParameter : 0"
      :parameterName="selectedParameter ? selectedParameter.parameterName : ''"
    />
    <Confirm
      id="delete-parameter"
      :title="$t('views.caseTemplateEdition.deleteRow')"
      :message="$t('views.caseTemplateEdition.deleteConfirm')"
      @onOk="DeleteParameter"
    />
    <Confirm
      id="delete-row"
      :title="$t('views.caseTemplateEdition.deleteRow')"
      :message="$t('views.caseTemplateEdition.deleteConfirm')"
      @onOk="DeleteRow"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Alert from "@/components/general/alert";
import NewRowModal from "@/components/CaseTemplateEdition/NewRowModal";
import NewParamModal from "@/components/CaseTemplateEdition/NewParamModal";
import UpdateRowModal from "@/components/CaseTemplateEdition/UpdateRowModal";
import AddGeneralSummation from "../components/CaseTemplateEdition/AddGeneralSummation.vue";
import UpdateParamModal from "@/components/CaseTemplateEdition/UpdateParamModal";
import Confirm from "@/components/Confirm";
import RenameCostCalculationTemplates from "@/components/CaseTemplateEdition/RenameCostCalculationTemplates";
import BasisInformation from "@/components/CaseTemplateEdition/TabBasisInformation.vue";

const components = {
  NewRowModal,
  NewParamModal,
  UpdateRowModal,
  UpdateParamModal,
  Confirm,
  Alert,
  AddGeneralSummation,
  RenameCostCalculationTemplates,
  BasisInformation,
};

const data = () => {
  return {
    busy: true,
    show: false,
    change: false,
    selectedRow: null,
    selectedParameter: null,
    busyTable: false,
    selectedGeneralSummation: null,
  };
};

const computed = {
  ...mapGetters("CaseTemplates", {
    caseTemplate: "caseTemplate",
    caseTemplateGeneralSummations: "caseTemplateGeneralSummations",
  }),
  fields() {
    return [
      {
        key: "rowName",
        label: this.$t("views.caseTemplateEdition.subcaseName"),
        tdClass: "text-left",
      },
      {
        key: "masterFormName",
        label: this.$t("views.caseTemplateEdition.masterForm"),
        tdClass: "text-left",
      },
      {
        key: "blocked",
        label: this.$t("views.caseTemplateEdition.blocked"),
        tdClass: "text-left",
      },
    ];
  },
  paramFields() {
    return [
      {
        key: "idParameter",
        label: this.$t("views.caseTemplateEdition.idParameter"),
        tdClass: "text-left",
      },
      {
        key: "parameterName",
        label: this.$t("views.caseTemplateEdition.parameterName"),
        tdClass: "text-left",
      },
      {
        key: "defaultValue",
        label: this.$t("views.caseTemplateEdition.defaultValue"),
        tdClass: "text-left",
      },
    ];
  },
  generalSummationsFields() {
    return [
      {
        key: "generalSummationName",
        label: this.$t("views.caseTemplateEdition.generalSummation"),
        tdClass: "text-left",
      },
      {
        key: "showSubcases",
        label: this.$t("views.caseTemplateEdition.showSubcases"),
        tdClass: "text-left",
      },
      {
        key: "showBudget",
        label: this.$t("views.caseTemplateEdition.showBudget"),
        tdClass: "text-left",
      },
    ];
  },
};

const methods = {
  updateShowSubcases(e) {
    this.tableBusy = true;
    this.updateGeneralSummations({
      idGeneralSummation: e.item.idGeneralSummation,
      idCaseTemplate: e.item.idCaseTemplate,
      showBudget: e.item.showBudget,
      showSubcases: e.item.showSubcases,
      api: this.$api,
    }).finally(() => {
      this.tableBusy = false;
    });
  },
  ...mapActions("CaseTemplates", {
    getCaseTemplate: "getCaseTemplate",
    deleteRow: "deleteRow",
    updateRow: "updateRow",
    deleteParameter: "deleteParameter",
    getCaseTemplateGeneralSummations: "getCaseTemplateGeneralSummations",
    updateGeneralSummations: "updateGeneralSummations",
    deleteGeneralSummations: "deleteGeneralSummations",
    getGeneralSummations: "getGeneralSummations",
  }),
  onDeleteGeneralSummation() {
    this.tableBusy = true;
    this.deleteGeneralSummations({
      idGeneralSummation: this.selectedGeneralSummation.idGeneralSummation,
      idCaseTemplate: this.selectedGeneralSummation.idCaseTemplate,
      api: this.$api,
    }).finally(() => {
      this.tableBusy = false;
      this.$bvModal.hide("delete-general-summation");
    });
  },
  DeleteRow() {
    this.tableBusy = true;
    this.deleteRow({
      idCaseTemplate: this.selectedRow.idCaseTemplate,
      idTemplateRow: this.selectedRow.idTemplateRow,
      api: this.$api,
    }).finally(() => {
      this.tableBusy = false;
      this.$bvModal.hide("delete-row");
    });
  },
  DeleteParameter() {
    this.tableBusy = true;
    this.deleteParameter({
      idCaseTemplate: this.selectedParameter.idCaseTemplate,
      idParameter: this.selectedParameter.idParameter,
      api: this.$api,
    }).finally(() => {
      this.tableBusy = false;
      this.$bvModal.hide("delete-parameter");
    });
  },
  moveUp() {
    const initialIndex = this.caseTemplate.rows.indexOf(this.selectedRow);
    var upper = this.caseTemplate.rows[initialIndex - 1];
    const newIndex = upper.index;
    upper.index = this.selectedRow.index;
    this.selectedRow.index = newIndex;

    this.tableBusy = true;
    this.updateRow({
      api: this.$api,
      idCaseTemplateRow: this.selectedRow.idTemplateRow,
      idCaseTemplate: this.caseTemplate.idCaseTemplate,
      name: this.selectedRow.rowName,
      idMasterForm: this.selectedRow.idMasterForm,
      blocked: this.selectedRow.blocked,
      index: this.selectedRow.index,
    })
      .then(() => {
        this.updateRow({
          api: this.$api,
          idCaseTemplateRow: upper.idTemplateRow,
          idCaseTemplate: this.caseTemplate.idCaseTemplate,
          name: upper.rowName,
          idMasterForm: upper.idMasterForm,
          blocked: upper.blocked,
          index: upper.index,
        });
      })
      .finally(() => {
        this.tableBusy = false;
      });

    this.sortRows();
  },
  moveDown() {
    const initialIndex = this.caseTemplate.rows.indexOf(this.selectedRow);
    var lower = this.caseTemplate.rows[initialIndex + 1];
    const newIndex = lower.index;
    lower.index = this.selectedRow.index;
    this.selectedRow.index = newIndex;

    this.tableBusy = true;
    this.updateRow({
      api: this.$api,
      idCaseTemplateRow: this.selectedRow.idTemplateRow,
      idCaseTemplate: this.caseTemplate.idCaseTemplate,
      name: this.selectedRow.rowName,
      idMasterForm: this.selectedRow.idMasterForm,
      blocked: this.selectedRow.blocked,
      index: this.selectedRow.index,
    })
      .then(() => {
        this.updateRow({
          api: this.$api,
          idCaseTemplateRow: lower.idTemplateRow,
          idCaseTemplate: this.caseTemplate.idCaseTemplate,
          name: lower.rowName,
          idMasterForm: lower.idMasterForm,
          blocked: lower.blocked,
          index: lower.index,
        });
      })
      .finally(() => {
        this.tableBusy = false;
      });

    this.sortRows();
  },
  sortRows() {
    this.caseTemplate.rows.sort(function (a, b) {
      return a.index - b.index;
    });
  },
  onRowSelected(selected) {
    this.selectedRow = selected[0];
  },
  onParameterSelected(selected) {
    this.selectedParameter = selected[0];
  },
  onGeneralSummationSelected(selected) {
    this.selectedGeneralSummation = selected[0];
  },
};
const lifeCycle = {
  created() {
    const { idCaseTemplate } = this.$route.params;
    if (!idCaseTemplate) return;
    this.busy = true;
    let that = this;
    this.getCaseTemplate({
      api: this.$api,
      idCaseTemplate,
    })
      .then(() => {
        that.getCaseTemplateGeneralSummations({
          api: that.$api,
          idCaseTemplate,
        });
      })
      .then(() => {
        that.getGeneralSummations({ api: that.$api });
      })
      .finally(() => {
        this.busy = false;
        this.change = false;
      });
  },
};
export default {
  components,
  data,
  computed,
  methods,
  ...lifeCycle,
};
</script>

<style></style>
