<template>
  <b-modal
    :id="idModal"
    ref="edit-basis-information-modal-form-study-type"
    :title="$t('views.caseTemplateEdition.fields.update-study-type')"
    centered
    header-bg-variant="primary"
    header-text-variant="light"
    :ok-title="$t('general.ok')"
    :cancel-title="$t('general.cancel')"
    :busy="loading"
    v-model="modalShow"
    @ok="handleOk"
    @hidden="resetModal"
    @show="onShow"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :label="$t('views.caseTemplateEdition.fields.study-type')"
        label-for="value-input"
        label-cols="12"
        content-cols="12"
        :busy="loading"
      >
        <b-form-select
          v-model="conditionSelected"
          :options="conditions"
          text-field="conditionName"
          value-field="idCondition"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("views.caseTemplateEdition.fields.select-study")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    show: Boolean,
    idCaseTemplate: Number,
    selectedRow: Object,
  },
  data() {
    return {
      idModal: "edit-basis-information-modal-form-study-type",
      loading: false,
      conditionSelected: null,
    };
  },
  computed: {
    ...mapGetters("Conditions", ["conditions"]),
    modalShow() {
      return this.show;
    },
  },
  methods: {
    ...mapActions("Conditions", ["getConditions"]),
    ...mapActions("CaseTemplates", ["putCaseTemplateFieldStudytype"]),
    resetModal() {
      this.loading = false;
      this.conditionSelected = null;
      this.$emit("modalClose");
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.loading = true;
      this.putCaseTemplateFieldStudytype({
        idCaseTemplate: this.idCaseTemplate,
        idStudyType: this.conditionSelected,
      })
        .then((response) => {
          this.$bvToast.toast(response.data.information.text, {
            variant: "success",
            title: this.$t(
              "views.caseTemplateEdition.fields.update-study-type"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide(this.idModal);
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(
            errorMsg != undefined
              ? errorMsg
              : this.$t("views.caseTemplateEdition.fields.generic-forbidden"),
            {
              variant: "danger",
              title: this.$t(
                "views.caseTemplateEdition.fields.update-study-type"
              ),
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.loading = false;
          this.state = {
            name: null,
          };
        });
    },
    onShow() {
      this.loading = true;
      this.getConditions({
        session: this.$session,
        api: this.$api,
        enabled: true,
      }).catch((e) => {
        console.log(e);
      });
      this.conditionSelected = this.selectedRow.studyType;
      this.loading = false;
    },
  },
};
</script>
