<template>
  <b-button
    variant="primary"
    v-b-tooltip.hover
    :title="$t('views.caseTemplateEdition.fields.move-down')"
    size="sm"
    @click="moveDown"
    :disabled="disabled"
  >
    <b-icon-arrow-down />
  </b-button>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    disabled: Boolean,
    idCaseTemplate: Number,
    selectedRow: Object,
    items: Array,
  },
  methods: {
    ...mapActions("CaseTemplates", ["putMoveDownField"]),
    moveDown() {
      const index = this.items.indexOf(this.selectedRow);
      const sequence = this.selectedRow.sequence;
      if (index >= 0) {
        const movedItem = this.items.find((item) => item.sequence === sequence);
        const nextItem = this.items.find(
          (item) => item.sequence === sequence + 1
        );
        if (movedItem && nextItem) {
          const idMove = movedItem.idCaseTemplateField;
          const idMoveNext = nextItem.idCaseTemplateField;
          this.putMoveDownField({
            idCaseTemplateField: idMove,
            idFieldToChangePosition: idMoveNext,
          })
            .catch((errorMsg) => {
              this.$bvToast.toast(
                errorMsg != undefined
                  ? errorMsg
                  : this.$t(
                      "views.caseTemplateEdition.fields.generic-forbidden"
                    ),
                {
                  variant: "danger",
                  title: this.$t("views.caseTemplateEdition.fields.move-down"),
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              this.loading = false;
              this.state = {
                name: null,
              };
            })
            .finally(() => {
              this.$emit("onMove");
            });
        }
      }
    },
  },
};
</script>
