<template>
  <b-button
    variant="primary"
    v-b-tooltip.hover
    size="sm"
    :title="$t('views.caseTemplateEdition.fields.edit-field')"
    :disabled="!selectedRow"
    @click="getIdRow"
  >
    <b-icon-pencil />
    <FormName
      :show="formName"
      @modalClose="resetShow"
      :selectedRow="selectedRow"
      :idCaseTemplate="idCaseTemplate"
    />
    <FormStudyType
      :show="formStudyType"
      @modalClose="resetShow"
      :selectedRow="selectedRow"
      :idCaseTemplate="idCaseTemplate"
    />
    <FormDuration
      :show="formDuration"
      @modalClose="resetShow"
      :selectedRow="selectedRow"
      :idCaseTemplate="idCaseTemplate"
    />
    <FormDefault
      :show="formDefault"
      @modalClose="resetShow"
      :selectedRow="selectedRow"
      :idCaseTemplate="idCaseTemplate"
    />
  </b-button>
</template>

<script>
import FormName from "@/components/CaseTemplateEdition/FormName.vue";
import FormStudyType from "@/components/CaseTemplateEdition/FormStudyType.vue";
import FormDuration from "@/components/CaseTemplateEdition/FormDuration.vue";
import FormDefault from "@/components/CaseTemplateEdition/FormDefault.vue";

export default {
  components: {
    FormName,
    FormStudyType,
    FormDuration,
    FormDefault,
  },
  props: {
    busy: Boolean,
    idCaseTemplate: Number,
    selectedRow: Object,
  },
  data() {
    return {
      show: false,
      formName: false,
      formStudyType: false,
      formDuration: false,
      formDefault: false,
      loading: false,
    };
  },
  methods: {
    resetShow() {
      this.formName = false;
      this.formStudyType = false;
      this.formDuration = false;
      this.formDefault = false;
    },
    getIdRow() {
      let idRowSelected = this.selectedRow.idCaseTemplateField;
      switch (idRowSelected) {
        case -1:
          this.formName = true;
          break;
        case -2:
          this.formStudyType = true;
          break;
        case -3:
          this.formDuration = true;
          break;
        default:
          this.formDefault = true;
          break;
      }
    },
  },
};
</script>
