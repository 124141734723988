<template>
  <b-modal
    :id="idModal"
    ref="edit-basis-information-modal-form-duration"
    :title="$t('views.caseTemplateEdition.fields.update-duration')"
    centered
    header-bg-variant="primary"
    header-text-variant="light"
    :ok-title="$t('general.ok')"
    :cancel-title="$t('general.cancel')"
    :busy="loading"
    v-model="modalShow"
    @ok="handleOk"
    @hidden="resetModal"
    @show="onShow"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :label="$t('views.caseTemplateEdition.fields.value')"
        label-for="value-input"
        label-cols="12"
        content-cols="12"
      >
        <b-form-input
          :placeholder="
            $t('views.caseTemplateEdition.fields.value-placeholder')
          "
          v-model="form.value"
          type="number"
          :number="true"
          trim
          @change="verifyNegativeValues"
          min="0"
        ></b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    show: Boolean,
    idCaseTemplate: Number,
    selectedRow: Object,
  },
  data() {
    return {
      form: {
        value: 0,
      },
      idModal: "edit-basis-information-modal-form-duration",
      loading: false,
    };
  },
  computed: {
    modalShow() {
      return this.show;
    },
  },
  methods: {
    ...mapActions("CaseTemplates", ["putCaseTemplateFieldDuration"]),
    resetModal() {
      this.form = {
        value: 0,
      };
      this.loading = false;
      this.$emit("modalClose");
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    verifyNegativeValues() {
      if (this.form.value < 0 || this.form.value == "") {
        this.form.value = 0;
      }
    },
    handleSubmit() {
      this.loading = true;
      this.putCaseTemplateFieldDuration({
        idCaseTemplate: this.idCaseTemplate,
        duration: this.form.value,
      })
        .then((response) => {
          this.$bvToast.toast(response.data.information.text, {
            variant: "success",
            title: this.$t("views.caseTemplateEdition.fields.update-duration"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide(this.idModal);
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(
            errorMsg != undefined
              ? errorMsg
              : this.$t("views.caseTemplateEdition.fields.generic-forbidden"),
            {
              variant: "danger",
              title: this.$t(
                "views.caseTemplateEdition.fields.update-duration"
              ),
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.loading = false;
          this.state = {
            name: null,
          };
        });
    },
    onShow() {
      this.form.value = this.selectedRow.fieldValue;
    },
  },
};
</script>
