<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-modal.modal-rename-cost-calculation-template
    v-if="isAdminCustomer"
  >
    <b-icon-pencil />

    <b-modal
      id="modal-rename-cost-calculation-template"
      ref="modal"
      :title="$t('views.caseTemplateEdition.EditName')"
      :busy="busy"
      @show="show"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-overlay :show="busy" rounded="sm">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="$t('views.caseTemplateEdition.Name')"
            label-for="name-input"
            :invalid-feedback="$t('views.caseTemplateEdition.NameIsRequired')"
            :state="nameState"
          >
            <b-form-input
              id="name-input"
              v-model="name"
              :state="nameState"
              required
            ></b-form-input>
          </b-form-group>
        </form>
      </b-overlay>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      name: "",
      nameState: null,
      busy: false,
    };
  },
  computed: {
    ...mapGetters("CaseTemplates", {
      caseTemplate: "caseTemplate",
    }),
    ...mapGetters({
      isAdminCustomer: "GET_ISADMINCUSTOMER",
    }),
  },
  methods: {
    ...mapActions("CaseTemplates", { rename: "rename" }),
    show() {
      this.name = this.caseTemplate.caseTemplateName;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
      this.busy = false;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.busy = true;
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.name === this.caseTemplate.caseTemplateName) {
        this.$nextTick(() => {
          this.$bvModal.hide("modal-rename-cost-calculation-template");
        });
        return;
      }
      this.rename({
        api: this.$api,
        idCaseTemplate: this.caseTemplate.idCaseTemplate,
        caseTemplateName: this.name,
      })
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: this.$t("views.caseTemplateEdition.EditName"),
            autoHideDelay: 3000,
            variant: "success",
            appendToast: true,
          });
        })
        .finally(() => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-rename-cost-calculation-template");
          });
        });
    },
  },
};
</script>

<style></style>
