<template>
  <b-modal
    ref="new-row"
    id="new-row-modal"
    size="lg"
    :title="$t('views.caseTemplateEdition.newRow')"
    @show="resetModal"
  >
    <b-form-group
      :label="$t('views.caseTemplateEdition.subcaseName')"
      label-for="NewRowName"
      :invalid-feedback="subcaseNameFeedback"
      label-cols="3"
      :state="rowNameState"
    >
      <b-form-input
        :placeholder="$t('views.caseTemplateEdition.subcaseNameDescription')"
        v-model="form.rowName"
        id="NewRowName"
        required
        trim
        :state="rowNameState"
        :formatter="formatter"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      :label="$t('views.caseTemplateEdition.masterForm')"
      label-for="NewRowMasterFormDropdown"
      :invalid-feedback="$t('views.cases.masterFormRequired')"
      label-cols="3"
      :state="idMasterFormState"
      inline
    >
      <b-input-group>
        <b-form-select
          v-model="form.idMasterForm"
          required
          :state="idMasterFormState"
          :disabled="busy"
          id="NewRowMasterFormDropdown"
        >
          <template #first>
            <b-form-select-option :value="null" disabled style="opacity: 0.5">{{
              $t("views.cases.masterFormInstructions")
            }}</b-form-select-option>
          </template>
          <b-form-select-option
            id="select-1"
            v-for="(masterForm, index) in masterForms"
            v-bind:key="index"
            :value="masterForm.idMasterForm"
            >{{ masterForm.name }}</b-form-select-option
          >
        </b-form-select>
        <template #append>
          <b-button
            @click="loadMasterForms"
            size="sm"
            variant="outline-info"
            :disables="busy"
          >
            <b-icon-arrow-clockwise />
          </b-button>
        </template>
      </b-input-group>
    </b-form-group>
    <b-form-group
      :label="$t('views.caseTemplateEdition.blocked')"
      label-for="NewRowBlocked"
      label-cols="3"
    >
      <b-form-checkbox
        v-model="form.blocked"
        id="NewRowBlocked"
        button
        :button-variant="form.blocked ? 'danger' : 'success'"
      >
        {{
          form.blocked
            ? $t("views.caseTemplateEdition.blockedStatus")
            : $t("views.caseTemplateEdition.unblocked")
        }}
      </b-form-checkbox>
    </b-form-group>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button
          variant="primary"
          @click="AddRow"
          :disabled="busy"
          id="NewRowAddButton"
        >
          {{ $t("views.caseTemplateEdition.addRow") }}
        </b-button>
      </slot>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

const initForm = {
  rowName: null,
  idMasterForm: null,
  blocked: false,
};

export default {
  props: {
    idCaseTemplate: {
      required: true,
      type: Number,
    },
    idOrganizationalUnit: {
      required: true,
      type: Number,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      form: { ...initForm },
      busy: false,
      rowNameState: null,
      idMasterFormState: null,
      subcaseNameFeedback: this.$t(
        "views.caseTemplateEdition.subcaseNameRequired"
      ),
      caseNameRegex: /\/|\*|\?|\[|\]|:|\|/g,
    };
  },
  computed: {
    ...mapGetters("CaseTemplates", ["masterForms"]),
  },
  methods: {
    ...mapActions("CaseTemplates", {
      addRow: "addRow",
      getMasterForms: "getMasterForms",
    }),
    formatter(value) {
      if (value.match(this.caseNameRegex)) {
        this.subcaseNameFeedback = this.$t("views.cases.notAllowedSymbols");
        this.rowNameState = false;
      } else {
        this.rowNameState = null;
      }
      return value.replace(this.caseNameRegex, "");
    },
    checkFormValidity() {
      let check = (this.rowNameState = this.idMasterFormState = true);
      if (!this.form.rowName) {
        this.rowNameState = check = false;
        this.subcaseNameFeedback = this.$t(
          "views.caseTemplateEdition.subcaseNameRequired"
        );
      }
      if (!this.form.idMasterForm) this.idMasterFormState = check = false;
      return check;
    },
    resetModal() {
      this.form = { ...initForm };
      this.rowNameState = null;
      this.idMasterFormState = null;
      this.loadMasterForms();
    },
    AddRow(oEvent) {
      if (!this.checkFormValidity()) {
        oEvent.preventDefault();
        return;
      }
      this.addRow({
        api: this.$api,
        idCaseTemplate: this.idCaseTemplate,
        rowName: this.form.rowName,
        idMasterForm: this.form.idMasterForm,
        blocked: this.form.blocked,
        index: this.index,
      });

      this.$refs["new-row"].hide();
    },
    loadMasterForms() {
      this.busy = true;
      this.getMasterForms({
        api: this.$api,
        idOrganizationalUnit: this.idOrganizationalUnit,
      }).finally(() => {
        this.busy = false;
      });
    },
  },
};
</script>
