<template>
  <b-modal
    ref="new-row"
    id="add-general-summation"
    :title="$t('views.caseTemplateEdition.addNewGeneralSummation')"
  >
    <b-list-group>
      <b-list-group-item
        class="d-flex justify-content-between"
        v-for="(generalSummationItem, index) in availableGeneralSummations"
        v-bind:key="'generalSummation' + index"
      >
        {{ generalSummationItem.generalSummationName
        }}<b-button
          pill
          variant="info"
          class="mr-3"
          @click="addGeneralSummation(generalSummationItem)"
          >+</b-button
        >
      </b-list-group-item>
    </b-list-group>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button
          @click="$bvModal.hide('add-general-summation')"
          :disabled="okLoading"
          >{{ $t("general.cancel") }}</b-button
        ><b-button
          variant="primary"
          @click="$bvModal.hide('add-general-summation')"
          :disabled="okLoading"
          >{{ $t("general.ok") }}</b-button
        ></slot
      >
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    idCaseTemplate: {
      required: true,
      type: Number,
    },
    idOrganizationalUnit: {
      required: true,
      type: Number,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("CaseTemplates", {
      getCaseTemplateGeneralSummations: "getCaseTemplateGeneralSummations",
      createGeneralSummationCaseTemplate: "createGeneralSummationCaseTemplate",
    }),
    addGeneralSummation(e) {
      let that = this;
      const { idCaseTemplate } = this.$route.params;
      this.createGeneralSummationCaseTemplate({
        api: that.$api,
        idCaseTemplate: idCaseTemplate,
        idGeneralSummation: e.idGeneralSummation,
      });
    },
  },
  computed: {
    ...mapGetters("CaseTemplates", {
      generalSummations: "generalSummations",
      caseTemplateGeneralSummations: "caseTemplateGeneralSummations",
    }),
    availableGeneralSummations() {
      let ags = [];
      this.generalSummations.forEach((element) => {
        let found = this.caseTemplateGeneralSummations.find(
          (item) => item.idGeneralSummation === element.idGeneralSummation
        );
        if (!found) ags.push(element);
      });
      return ags;
    },
  },
};
</script>
