<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-modal="idModal"
    v-b-tooltip.hover
    :title="$t('views.caseTemplateEdition.delete-template')"
    id="delete-case-template-field-button"
  >
    <b-icon icon="trash" />
    <Confirm
      :id="idModal"
      :title="$t('views.caseTemplateEdition.delete-template')"
      :message="$t('views.caseTemplateEdition.delete-case-template')"
      @onOk="removeCaseTemplateField"
      variantHeader="danger"
      variantText="light"
    />
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
import Confirm from "@/components/Confirm.vue";
export default {
  components: {
    Confirm,
  },

  props: {
    rowSelected: Object,
    idCaseTemplate: Number,
  },

  data() {
    return {
      idModal: "delete-case-template-field",
    };
  },
  methods: {
    ...mapActions("CaseTemplates", [
      "deleteCaseTemplateField",
      "getCaseTemplateFields",
    ]),
    removeCaseTemplateField() {
      this.deleteCaseTemplateField({
        idCaseTemplateField: this.rowSelected.idCaseTemplateField,
      })

        .then((response) => {
          this.$bvToast.toast(response.data.information.text, {
            variant: "success",
            title: this.$t("views.caseTemplateEdition.fields.delete-field"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$emit("onDelete");
          this.getCaseTemplateFields({
            idCaseTemplate: this.idCaseTemplate,
          });
        })

        .catch((failure) => {
          this.$bvToast.toast(
            failure != undefined
              ? failure
              : this.$t("views.caseTemplateEdition.fields.generic-forbidden"),
            {
              variant: "danger",
              title: this.$t("views.caseTemplateEdition.fields.delete-field"),
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        })

        .finally(() => {
          this.$bvModal.hide(this.idModal);
        });
    },
  },
};
</script>
