<template>
  <b-modal
    ref="update-parameter"
    id="update-parameter-modal"
    :title="$t('views.caseTemplateEdition.updateRow') + parameterName"
    @show="resetModal"
  >
    <b-form-group
      :label="$t('views.caseTemplateEdition.defaultValue')"
      label-for="input-value"
      :invalid-feedback="$t('views.caseTemplateEdition.defaultValueRequired')"
      label-cols="3"
      :state="defaultValueState"
    >
      <b-form-input
        :placeholder="$t('views.caseTemplateEdition.defaultValueDescription')"
        v-model="form.defaultValue"
        id="input-value"
        required
        trim
        type="number"
        :number="true"
        :state="defaultValueState"
      ></b-form-input>
    </b-form-group>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button variant="primary" @click="SaveRow" :disabled="busy">
          {{ $t("views.caseTemplateEdition.saveRow") }}
        </b-button>
      </slot>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

const initForm = {
  defaultValue: null,
};

export default {
  props: {
    idCaseTemplate: {
      required: true,
      type: Number,
    },
    parameterName: {
      required: true,
      type: String,
    },
    idParameter: {
      required: true,
      type: Number,
    },
    defaultValue: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      form: { ...initForm },
      busy: false,
      defaultValueState: null,
    };
  },
  methods: {
    ...mapActions("CaseTemplates", {
      updateParameter: "updateParameter",
    }),
    checkFormValidity() {
      let check = (this.defaultValueState = true);
      if (!this.form.defaultValue) this.defaultValueState = check = false;
      return check;
    },
    resetModal() {
      this.form = {
        defaultValue: this.defaultValue,
      };
      this.defaultValueState = null;
    },
    SaveRow(oEvent) {
      if (!this.checkFormValidity()) {
        oEvent.preventDefault();
        return;
      }
      this.updateParameter({
        api: this.$api,
        idCaseTemplate: this.idCaseTemplate,
        idParameter: this.idParameter,
        defaultValue: this.form.defaultValue,
      });

      this.$refs["update-parameter"].hide();
    },
  },
};
</script>
